<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-08 21:30:53
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-14 15:38:52
 * @Description: 修改库位绑定
 * @FilePath: \src\views\Storage\modifyStorage.vue
-->

<template>
  <div class="kc-app">
    <van-nav-bar
      title="修改库位绑定"
      left-arrow
      :fixed="true"
      :placeholder="true"
      :z-index="500"
      :safe-area-inset-top="true"
      @click-left="$router.go(-1)"
    />

    <!-- 库位RFID -->
    <van-field
      v-model="kst_tag_id"
      label="库位RFID"
      ref="kst_tag_input_dom"
      autocomplete="off"
      @click="gainFocus()"
      placeholder="等待库位ID输入"
    />

    <!-- 客户选择 -->
    <van-field
      v-model="customer_name"
      readonly
      label="客户选择"
      placeholder="请选择客户"
      @click="showCustomerPicker = true"
    />

    <!-- 备注 -->
    <van-field
      v-model="notes"
      rows="3"
      autosize
      label="备注"
      type="textarea"
      maxlength="150"
      placeholder="备注信息"
      show-word-limit
    />

    <van-divider></van-divider>

    <div class="btn-box">
      <van-button
        style="width: 30%; margin-right: 15px"
        round
        size="small"
        icon="exchange"
        type="success"
        @click="modifyStorage"
      >
        确认
      </van-button>
      <van-button
        style="width: 30%"
        round
        size="small"
        icon="replay"
        type="default"
        @click="initData"
      >
        重置
      </van-button>
    </div>

    <!-- 客户选择器 -->
    <customer-picker
      v-model:show="showCustomerPicker"
      v-model:customer_id="customer_id"
      v-model:customer_name="customer_name"
    ></customer-picker>
  </div>
</template>

<script>
import { modifyStorageByTagId } from "./api";
import { getCurrentInstance, onMounted, ref } from "vue";
import customerPicker from "@/components/customerPicker";
export default {
  components: {
    customerPicker,
  },
  setup() {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;
    const kst_tag_input_dom = ref(null); //Dom对象

    const kst_tag_id = ref(""); //库位RFID
    const customer_name = ref(""); //客户姓名
    const customer_id = ref(""); //客户UID
    const showCustomerPicker = ref(false); //控制客户选择是否显示
    const notes = ref(""); //备注

    //控制输入框获取焦点单不弹出键盘
    const gainFocus = () => {
      initExpressInput(); //初始化输入框
      //找到input的dom对象
      const inpdom =
        kst_tag_input_dom.value.$el.children[1].children[0].children[0];
      inpdom.setAttribute("readonly", "readonly");
      inpdom.focus();
      setTimeout(() => {
        inpdom.removeAttribute("readonly");
      }, 200);
    };

    //初始化快递单号输入框
    const initExpressInput = () => {
      kst_tag_id.value = "";
    };

    const initData = () => {
      kst_tag_id.value = ""; //库位ID
      customer_id.value = ""; //客户ID
      customer_name.value = ""; //客户姓名
      notes.value = ""; //描述
    };

    //提交入库请求
    const modifyStorage = () => {
      const params = {
        kst_tag_id: kst_tag_id.value, //库位ID
        kcu_id: customer_id.value, //客户ID
        notes: notes.value, //描述
      };
      modifyStorageByTagId(params)
        .then((res) => {
          initData();
          utils.success(res.err_msg, 800);
        })
        .catch((err) => {
          utils.fail(err.err_msg, 1000);
        });
    };

    onMounted(() => {
      gainFocus();
    });

    return {
      kst_tag_input_dom,
      gainFocus,
      modifyStorage,
      kst_tag_id,
      initData,
      notes,
      showCustomerPicker,
      customer_name,
      customer_id,
    };
  },
};
</script>
